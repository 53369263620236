@import "https://fonts.googleapis.com/css2?family=Sora&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Sora, sans-serif;
}

:root {
  --colorNavBar: #05001a;
  --colorNavLink: #a883c4;
  --colorNavLinkHover: #c5c2d6;
  --colorShades0: #1c0e61;
  --colorShades1: #382c75;
  --colorShades2: #554a89;
  --colorShades3: #71689c;
  --colorShades4: #8e87b0;
  --colorShades5: #aaa5c4;
  --colorShades6: #c6c3d7;
  --colorShades7: #e3e1eb;
  --caru1: "images/screen-1.png";
  --caru2: "images/screen-2.png";
  --caru3: "images/screen-3.png";
  --caru4: "images/screen-4.png";
  --caru5: "images/screen-5.png";
  --caru6: "images/screen-6.png";
  --caru7: "images/screen-7.png";
  --caru8: "images/screen-8.png";
  --caru9: "images/screen-9.png";
  --caru10: "images/screen-10.png";
  --caru11: "images/screen-11.png";
  --caru12: "images/screen-12.png";
}

label {
  width: 40px;
  cursor: pointer;
  flex-direction: column;
  display: flex;
}

label span {
  background: var(--colorNavLink);
  height: 5px;
  border-radius: 10px;
  margin: 4px 0;
  transition: all .4s cubic-bezier(.68, -.6, .32, 1.6);
}

span:nth-of-type(1) {
  width: 50%;
}

span:nth-of-type(2) {
  width: 100%;
}

span:nth-of-type(3) {
  width: 75%;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotateZ(45deg)translate(8px);
}

input[type="checkbox"]:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotateZ(-45deg);
}

input[type="checkbox"]:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(18px, -8px)rotateZ(45deg);
}

.carousel {
  width: 80%;
  height: 410px;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}

.carousel_img {
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  opacity: 1;
  transform: translateY(20px);
}

.carousel_track_container {
  height: 100%;
  background: #000;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.carousel_track {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: transform 1s ease-in-out;
  position: relative;
}

.carousel_slide {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.carousel_button {
  cursor: pointer;
  background: none;
  border: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.carousel_button img {
  width: 20px;
}

.carousel--left {
  left: -40px;
}

.carousel--right {
  right: -40px;
}

.carousel_nav {
  z-index: 11;
  justify-content: center;
  padding: 10px 0;
  display: flex;
}

.sidebar {
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #0d0d0d;
  align-items: center;
  transition: all .3s ease-in-out;
  display: grid;
  position: fixed;
  top: 80px;
  left: 0;
}

.sb_show {
  opacity: .9;
  z-index: 999;
  top: 80px;
}

.sidebar_wrapper {
  color: #fff;
}

.sidebar_menu {
  text-align: center;
  grid-template-rows: repeat(6, 80px);
  grid-template-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 480px) {
  .sidebar_menu {
    grid-template-rows: repeat(6, 60px);
  }
}

.sidebar_link {
  color: var(--colorNavLink);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: all .2s ease-in-out;
  display: flex;
}

.sidebar_link:hover {
  color: var(--colorNavLinkHover);
  transition: all .2s ease-in-out;
}

.sidebar_icon {
  cursor: pointer;
  background: none;
  outline: none;
  font-size: 2rem;
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
}

.nav {
  background: var(--colorNavBar);
  height: 80px;
  z-index: 10;
  width: 100%;
  justify-content: space-between;
  padding: .5rem calc(50vw - 500px);
  display: flex;
  position: sticky;
  top: 0;
}

.navlogo {
  color: #05001a;
  cursor: pointer;
  justify-self: flex-start;
  align-items: center;
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  display: flex;
}

.mobileicon {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobileicon {
    cursor: pointer;
    color: #0b81a7;
    font-size: 1.8rem;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
  }
}

.navmenu {
  align-items: center;
  margin-right: 10px;
  display: flex;
}

@media screen and (max-width: 768px) {
  .navmenu {
    display: none;
  }
}

.navlink {
  color: var(--colorNavLink);
  height: 100%;
  cursor: pointer;
  align-items: center;
  padding: 0 1rem 0 0;
  text-decoration: none;
  display: flex;
}

.navlink:hover {
  color: #c5c2d6;
  transition: all .2s ease-in-out;
}

.homecontainer {
  height: 100vh;
  z-index: 1;
  background: #0c0c0c;
  justify-content: center;
  align-items: flex-start;
  padding: 5% 30px;
  display: flex;
  position: relative;
  top: 0;
  overflow-x: hidden;
}

.homecontainer:before {
  content: "";
  z-index: 2;
  background: linear-gradient(#0003 0% 100%), linear-gradient(#0006 0%, #0000 100%);
  position: absolute;
  inset: 0;
}

@media screen and (max-width: 768px) {
  .homecontainer {
    padding: 5vh 10px;
  }
}

@media screen and (max-width: 480px) {
  .homecontainer {
    padding: 5vh 10px;
    position: relative;
  }
}

.homebg {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.homecontent {
  z-index: 3;
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
  padding: 8px 24px;
  display: flex;
  position: absolute;
}

.videobg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
}

@media (max-width: 767px) {
  .videobg {
    display: none;
  }

  .homebg {
    background-image: url("background.0be92781.jpg");
    background-position: center;
    background-size: cover;
  }
}

.button {
  white-space: nowrap;
  color: #010606;
  cursor: pointer;
  background: #01bf71;
  border: none;
  border-radius: 50px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  font-size: 16px;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
}

.button:hover {
  background: #fff;
  transition: all .2s ease-in-out;
}

.btndark {
  color: #fff;
  background: #010606;
}

.btndark:hover {
  background: #01bf71;
  transition: all .2s ease-in-out;
}

.homecontent {
  z-index: 3;
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
  padding: 8px 24px;
  display: flex;
  position: absolute;
}

.homeh1 {
  color: var(--colorShades3);
  text-align: center;
  text-shadow: 4px 4px #000;
  font-size: 46px;
}

@media screen and (max-width: 768px) {
  .homeh1 {
    font-size: 38px;
  }
}

@media screen and (max-width: 480px) {
  .homeh1 {
    font-size: 28px;
  }
}

.homep {
  color: var(--colorShades7);
  text-align: center;
  max-width: 700px;
  text-shadow: 2px 2px #000;
  margin-top: 24px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .homep {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .homep {
    font-size: 16px;
  }
}

.homebtnwrapper {
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  display: flex;
}

@keyframes colorChange {
  0% {
    background: radial-gradient(ellipse farthest-corner at top left, #fff 0%, var(--colorEverShades0) 8%, var(--colorEverShades3) 25%, var(--colorWhiteShades1) 62.5%, var(--colorWhiteShades7) 100%);
  }

  50% {
    background: radial-gradient(ellipse farthest-corner at top, #fff 0%, var(--colorEverShades0) 8%, var(--colorEverShades3) 25%, var(--colorWhiteShades1) 62.5%, var(--colorWhiteShades7) 100%);
  }

  100% {
    background: radial-gradient(ellipse farthest-corner at top right, #fff 0%, var(--colorEverShades0) 8%, var(--colorEverShades3) 25%, var(--colorWhiteShades1) 62.5%, var(--colorWhiteShades7) 100%);
  }
}

.infocontainer {
  color: #0c0c0c;
  background: var(--colorEverShades0);
  animation: 20s infinite colorChange;
  animation-play-state: "running";
}

@media screen and (max-width: 768px) {
  .infocontainer {
    padding: 100px 0;
  }
}

.infowrapper {
  z-index: 1;
  height: 900px;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  display: grid;
}

@media screen and (max-width: 768px) {
  .infowrapper {
    height: auto;
  }
}

.inforow1 {
  grid-template-areas: "col2 col1";
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  display: grid;
}

@media screen and (max-width: 768px) {
  .inforow1 {
    grid-template-areas: "col1 col1"
                         "col2 col2";
  }
}

.inforow2 {
  grid-template-areas: "col1 col2";
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  display: grid;
}

@media screen and (max-width: 768px) {
  .inforow2 {
    grid-template-areas: "col1 col1"
                         "col2 col2";
  }
}

.column1 {
  grid-area: col1;
  margin-bottom: 15px;
  padding: 0 15px;
}

.column2 {
  grid-area: col2;
  margin-bottom: 15px;
  padding: 0 15px;
}

.textwrapper {
  max-width: 548px;
  padding-top: 0;
  padding-bottom: 60px;
}

.topline {
  color: #00f691;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

.heading {
  color: var(--colorWhiteShades6);
  text-shadow: 2px 2px var(--colorWhiteShades0);
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .heading {
    font-size: 26px;
  }
}

.subtitle {
  max-width: 440px;
  color: #fff;
  text-shadow: 2px 2px var(--colorWhiteShades7);
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
}

.socialmedia {
  max-width: 500px;
  width: 100%;
  padding-bottom: 20px;
}

.socialmediawrap {
  width: 90%;
  max-width: 1000px;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto 0;
  display: flex;
}

@media screen and (max-width: 768px) {
  .socialmediawrap {
    flex-direction: column;
  }
}

.socialicons {
  color: #fff;
  width: auto;
  justify-content: space-between;
  justify-self: right;
  align-items: center;
  display: flex;
}

.socialiconlink {
  color: #fff;
  font-size: 20px;
}

.socialemail, .socialphone {
  color: #c2cddd;
  font-size: 16px;
}

.imgwrap {
  max-width: 555px;
  width: 700px;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .imgwrap {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .imgwrap {
    width: 100%;
    padding: 0 10px;
  }
}

.btnwrap {
  justify-content: flex-start;
  margin-top: 20px;
  display: flex;
}

.img {
  width: 100%;
  margin: 0 0 10px;
  padding-right: 0;
}

.imglink {
  width: 30px;
}

.itemlist {
  max-width: 440px;
  color: #fff;
  text-shadow: 2px 2px var(--colorWhiteShades7);
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
}

.divider, .itemdivider {
  border-top: 5px solid var(--colorShades2);
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: -10px;
}

.nametitle {
  max-width: 440px;
  color: #fff;
  text-shadow: 2px 2px var(--colorWhiteShades7);
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.footercontainer {
  background: var(--colorShades0);
  z-index: 9;
  background-image: url("footer.69ff736f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 2rem;
  display: flex;
  position: relative;
  top: 0;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .footercontainer {
    padding: 0 0 50px;
  }
}

.footerdivider {
  border-top: 4px solid var(--colorShades2);
  width: 100%;
  border-radius: 10px;
}

.footerheading {
  margin-bottom: 24px;
  font-family: Trebucket MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif;
  font-size: 24px;
}

.footersubtext {
  margin-bottom: 24px;
  font-size: 20px;
}

.footerlinkscontainer {
  width: 100%;
  max-width: 1000px;
  z-index: 3;
  align-items: stretch;
  display: flex;
}

@media screen and (max-width: 768px) {
  .footerlinkscontainer {
    justify-content: center;
    padding-top: 32px;
  }
}

.footerlinkswrapper {
  display: flex;
}

@media screen and (max-width: 768px) {
  .footerlinkswrapper {
    flex-direction: column;
  }
}

.footerlinksitems {
  text-align: left;
  width: auto;
  box-sizing: border-box;
  color: #fff;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  padding: 0 2rem;
  display: flex;
}

@media screen and (max-width: 950px) {
  .footerlinksitems {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 830px) {
  .footerlinksitems {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}

@media screen and (max-width: 480) {
  .footerlinksitems {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}

.sociallogo {
  color: #000;
  cursor: pointer;
  justify-self: start;
  align-items: center;
  margin-bottom: 16px;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
}

.footerlinktitle {
  color: var(--colorShades4);
  margin-bottom: 16px;
}

.footerlinktext {
  font-size: small;
}

.footerlinksmalltext {
  align-items: center;
  margin-left: 10px;
  font-size: x-small;
}

.websiterights {
  color: #fff;
}

.servicecontainer {
  width: 100%;
  height: 100%;
  z-index: 4;
  background: radial-gradient(ellipse farthest-corner at top left, var(--colorShades7) 0%, var(--colorShades6) 8%, var(--colorShades3) 25%, var(--colorShades1) 62.5%, var(--colorNavBar) 100%);
  padding: 5vh 0;
  position: relative;
  top: 0;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .servicecontainer {
    background: radial-gradient(ellipse farthest-corner at top right, var(--colorShades7) 0%, var(--colorShades6) 8%, var(--colorShades3) 25%, var(--colorShades1) 62.5%, var(--colorNavBar) 100%);
    padding: 10vh 0;
    position: relative;
  }
}

@media screen and (max-width: 480px) {
  .servicecontainer {
    background: radial-gradient(ellipse farthest-corner at top right, var(--colorShades7) 0%, var(--colorShades6) 8%, var(--colorShades3) 25%, var(--colorShades1) 62.5%, var(--colorNavBar) 100%);
    padding: 10vh 0;
    position: relative;
  }
}

.footerdivider {
  border-top: 5px solid var(--colorShades2);
  width: 100%;
  border-radius: 10px;
  margin-top: 0;
  margin-bottom: 10px;
}

.infowrapper {
  z-index: 1;
  height: 900px;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  display: grid;
}

@media screen and (max-width: 768px) {
  .infowrapper {
    height: auto;
  }
}

.inforow {
  grid-template-areas: "col2 col1";
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  display: grid;
}

@media screen and (max-width: 768px) {
  .inforow {
    grid-template-areas: "col1 col1"
                         "col2 col2";
  }
}

.inforowprod {
  grid-template-areas: "col1 col2";
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  display: grid;
}

@media screen and (max-width: 768px) {
  .inforowprod {
    grid-template-areas: "col1 col1"
                         "col2 col2";
  }
}

.column1 {
  grid-area: col1;
  margin-bottom: 15px;
  padding: 0 15px;
}

.column2 {
  grid-area: col2;
  margin-bottom: 15px;
  padding: 0 15px;
}

.textwrapper {
  max-width: 548px;
  padding-top: 0;
  padding-bottom: 60px;
}

.topline {
  color: #00f691;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

.heading {
  color: #f7f8fa;
  text-shadow: 2px 2px var(--colorShades0);
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .heading {
    font-size: 26px;
  }
}

.divider {
  border-top: 5px solid var(--colorShades2);
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.itemlist {
  max-width: 440px;
  color: #fff;
  text-shadow: 2px 2px var(--colorShades0);
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
}

.srvimgwrap {
  max-width: 555px;
  height: 100%;
}

.productscontainer {
  width: 100%;
  height: 100%;
  z-index: 5;
  background: radial-gradient(ellipse farthest-corner at top right, var(--colorShades7) 0%, var(--colorShades6) 8%, var(--colorShades3) 25%, var(--colorShades1) 62.5%, var(--colorNavBar) 100%);
  padding: 10vh 0;
  position: relative;
  top: 0;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .productscontainer {
    background: radial-gradient(ellipse farthest-corner at top left, var(--colorShades7) 0%, var(--colorShades6) 8%, var(--colorShades3) 25%, var(--colorShades1) 62.5%, var(--colorNavBar) 100%);
    padding: 10vh 0;
    position: relative;
  }
}

@media screen and (max-width: 480px) {
  .productscontainer {
    background: radial-gradient(ellipse farthest-corner at top right, var(--colorShades7) 0%, var(--colorShades6) 8%, var(--colorShades3) 25%, var(--colorShades1) 62.5%, var(--colorNavBar) 100%);
    padding: 10vh 0;
    position: relative;
  }
}

.productimgwrap {
  max-width: 100px;
  height: 100%;
}

.productimg {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.subtitleprod {
  max-width: 440px;
  color: #85e512;
  text-shadow: 2px 2px var(--colorShades0);
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 24px;
}

.textline {
  max-width: 400px;
  color: #fff;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
}

.weblink {
  color: var(--colorShades5);
  font-size: 15px;
}

.textwrapperprod {
  max-width: 400px;
  color: #fff;
  text-shadow: 2px 2px var(--colorShades0);
  padding-top: 0;
  padding-bottom: 20px;
}

.headingprod {
  color: #aaa5c4;
  text-shadow: 2px 2px var(--colorShades0);
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .headingprod {
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .headingprod {
    font-size: 26px;
  }
}

/*# sourceMappingURL=index.af7526c1.css.map */
