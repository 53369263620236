.carousel {
	position: relative;
	width: 80%;
	height: 410px;
	overflow: hidden;
	border-radius: 25px;
}

.carousel_img {
	width: 100%;
	object-fit: cover;
	vertical-align: middle;
	transform: translateY(20px);
	opacity: 100%;
}

.carousel_track_container {
	background:	black;
	height: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
}

.carousel_track {
	padding: 0;
	margin: 0;
	list-style: none;
	position: relative;
	height: 100%;
	transition: transform 1s ease-in-out;
}

.carousel_slide {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.carousel_button {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	background: transparent;
	border: 0;
	cursor: pointer;
}

.carousel_button img {
	width: 20px;
}

.carousel--left {
	left: -40px;
}

.carousel--right {
	right: -40px;
}

.carousel_nav {
	display: flex;
	justify-content: center;
	padding: 10px 0;
	z-index: 11;
}
