@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

* {
  font-family: "Sora", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --colorNavBar: #05001a;
  --colorNavLink: #a883c4;
  --colorNavLinkHover: #C5C2D6;
  --colorShades0: #1C0E61;
  --colorShades1: #382C75;
  --colorShades2: #554A89;
  --colorShades3: #71689C;
  --colorShades4: #8E87B0;
  --colorShades5: #AAA5C4;
  --colorShades6: #C6C3D7;
  --colorShades7: #E3E1EB;

  --caru1: "images/screen-1.png";
  --caru2: "images/screen-2.png";
  --caru3: "images/screen-3.png";
  --caru4: "images/screen-4.png";
  --caru5: "images/screen-5.png";
  --caru6: "images/screen-6.png";
  --caru7: "images/screen-7.png";
  --caru8: "images/screen-8.png";
  --caru9: "images/screen-9.png";
  --caru10: "images/screen-10.png";
  --caru11: "images/screen-11.png";
  --caru12: "images/screen-12.png";
}