@import "root.css";
@import "menu.css";
@import "carousel.css";

/* html {
  scroll-padding-top: 80px;
}

section {
  scroll-margin-top: 100%;
} */

.sidebar {
	position: fixed;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: #0d0d0d;
	display: grid;
	align-items: center;
	left: 0;
	transition: 0.3s ease-in-out;
	top: 80px;
	opacity: 0%;
}

.sb_show {
	top: 80px;
	opacity: 90%;
	z-index: 999;
}

.sidebar_wrapper {
	color: #fff;
}

.sidebar_menu {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(6, 80px);
	text-align: center;
}

@media screen and (max-width: 480px) {
	.sidebar_menu {
		grid-template-rows: repeat(6, 60px);
	}
}

.sidebar_link {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	text-decoration: none;
	list-style: none;
	transition: 0.2s ease-in-out;
	color: var(--colorNavLink);
	cursor: pointer;
}

.sidebar_link:hover {
	color: var(--colorNavLinkHover);
	transition: 0.2s ease-in-out;
}

.sidebar_icon {
	position: absolute;
	top: 1.2rem;
	right: 1.5rem;
	background: transparent;
	font-size: 2rem;
	cursor: pointer;
	outline: none;
}

.nav {
	background: var(--colorNavBar);
	height: 80px;
	display: flex;
	justify-content: space-between;
	padding: 0.5rem calc((100vw - 1000px) / 2);
	z-index: 10;
	position: sticky;
	top: 0;
	width: 100%;
}

.navlogo {
	color: #05001a;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-left: 10px;
	font-weight: bold;
	text-decoration: none;
}

.mobileicon {
	display: none;
}

@media screen and (max-width: 768px) {
	.mobileicon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		color: #0b81a7;
	}
}

.navmenu {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

@media screen and (max-width: 768px) {
	.navmenu {
		display: none;
	}
}

.navlink {
	color: var(--colorNavLink);
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem 0 0;
	height: 100%;
	cursor: pointer;
}

.navlink:hover {
	transition: all 0.2s ease-in-out;
	color: #c5c2d6;
}

.homecontainer {
	background: #0c0c0c;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 5% 30px;
	height: 100vh;
	z-index: 1;
	/* position: sticky; */
	position: relative;
	top: 0;
	overflow-x: hidden;
}

/* Tint the color of the video darker */
.homecontainer::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.2) 0%,
			rgba(0, 0, 0, 0.2) 100%
		),
		linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
	z-index: 2;
}

@media screen and (max-width: 768px) {
	.homecontainer {
		padding: 5vh 10px;
	}
}

@media screen and (max-width: 480px) {
	.homecontainer {
		position: relative;
		padding: 5vh 10px;
	}
}

.homebg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 1;
}

.homecontent {
	z-index: 3;
	max-width: 1200px;
	position: absolute;
	padding: 8px 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.videobg {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	background: #232a34;
}

/* Mobile devices typically have a width of less than 768px */
@media (max-width: 767px) {
	.videobg {
		/* Hide the video tag */
		display: none;
	}

	/* Use the "poster" image as a fallback background for the container. */
	.homebg {
		background-image: url("../images/background.jpg");
		background-size: cover;
		background-position: center;
	}
}

.button {
	border-radius: 50px;
	background: #01bf71;
	white-space: nowrap;
	padding: 12px 30px;
	color: #010606;
	font-size: 16px;
	outline: none;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
}

.button:hover {
	transition: all 0.2s ease-in-out;
	background: #fff;
}

.btndark {
	background: #010606;
	color: #fff;
}

.btndark:hover {
	transition: all 0.2s ease-in-out;
	background: #01bf71;
}

.homecontent {
	z-index: 3;
	max-width: 1200px;
	position: absolute;
	padding: 8px 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.homeh1 {
	color: var(--colorShades3);
	font-size: 46px;
	text-align: center;
	text-shadow: 4px 4px #000;
}

@media screen and (max-width: 768px) {
	.homeh1 {
		font-size: 38px;
	}
}

@media screen and (max-width: 480px) {
	.homeh1 {
		font-size: 28px;
	}
}

.homep {
	margin-top: 24px;
	color: var(--colorShades7);
	font-size: 20px;
	text-align: center;
	max-width: 700px;
	text-shadow: 2px 2px #000;
}

@media screen and (max-width: 768px) {
	.homep {
		font-size: 18px;
	}
}

@media screen and (max-width: 480px) {
	.homep {
		font-size: 16px;
	}
}

.homebtnwrapper {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@keyframes colorChange {
	0% {
		background: radial-gradient(
			ellipse farthest-corner at top left,
			#ffffff 0%,
			var(--colorEverShades0) 8%,
			var(--colorEverShades3) 25%,
			var(--colorWhiteShades1) 62.5%,
			var(--colorWhiteShades7) 100%
		);
	}

	50% {
		background: radial-gradient(
			ellipse farthest-corner at top,
			#ffffff 0%,
			var(--colorEverShades0) 8%,
			var(--colorEverShades3) 25%,
			var(--colorWhiteShades1) 62.5%,
			var(--colorWhiteShades7) 100%
		);
	}

	100% {
		background: radial-gradient(
			ellipse farthest-corner at top right,
			#ffffff 0%,
			var(--colorEverShades0) 8%,
			var(--colorEverShades3) 25%,
			var(--colorWhiteShades1) 62.5%,
			var(--colorWhiteShades7) 100%
		);
	}
}

.infocontainer {
	color: #0c0c0c;
	background: var(--colorEverShades0);
	animation: colorChange 20s infinite;
	animation-play-state: "running";
}

@media screen and (max-width: 768px) {
	.infocontainer {
		padding: 100px 0;
	}
}

.infowrapper {
	display: grid;
	z-index: 1;
	height: 900px;
	width: 100%;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 24px;
	justify-content: center;
}

@media screen and (max-width: 768px) {
	.infowrapper {
		height: auto;
	}
}

.inforow1 {
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);
	align-items: center;
	grid-template-areas: "col2 col1";
}

@media screen and (max-width: 768px) {
	.inforow1 {
		grid-template-areas: "col1 col1" "col2 col2";
	}
}

.inforow2 {
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);
	align-items: center;
	grid-template-areas: "col1 col2";
}

@media screen and (max-width: 768px) {
	.inforow2 {
		grid-template-areas: "col1 col1" "col2 col2";
	}
}

.column1 {
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col1;
}

.column2 {
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col2;
}

.textwrapper {
	max-width: 548px;
	padding-top: 0;
	padding-bottom: 60px;
}

.topline {
	color: #00f691;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 1.4px;
	text-transform: uppercase;
	margin-bottom: 16px;
}

.heading {
	margin-top: 24px;
	margin-bottom: 24px;
	font-size: 32px;
	line-height: 1.1;
	font-weight: 600;
	color: var(--colorWhiteShades6);
	text-shadow: 2px 2px var(--colorWhiteShades0);
}

@media screen and (max-width: 768px) {
	.heading {
		font-size: 32px;
	}
}

@media screen and (max-width: 480px) {
	.heading {
		font-size: 26px;
	}
}

.subtitle {
	max-width: 440px;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
	text-shadow: 2px 2px var(--colorWhiteShades7);
}

.socialmedia {
	max-width: 500px;
	width: 100%;
	padding-bottom: 20px;
}

.socialmediawrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	max-width: 1000px;
	margin: 40px auto 0 auto;
}

@media screen and (max-width: 768px) {
	.socialmediawrap {
		flex-direction: column;
	}
}

.socialicons {
	color: #fff;
	justify-self: right;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: auto;
}

.socialiconlink {
	color: #fff;
	font-size: 20px;
}

.socialemail {
	color: #c2cddd;
	font-size: 16px;
}

.socialphone {
	color: #c2cddd;
	font-size: 16px;
}

.imgwrap {
	max-width: 555px;
	width: 700px;
	height: 100%;
}

@media screen and (max-width: 768px) {
	.imgwrap {
		width: 90%;
	}
}

@media screen and (max-width: 480px) {
	.imgwrap {
		width: 100%;
		padding: 0 10px;
	}
}

.btnwrap {
	margin-top: 20px;
	display: flex;
	justify-content: flex-start;
}

.img {
	width: 100%;
	margin: 0 0 10px 0;
	padding-right: 0;
}

.imglink {
	width: 30;
}

.itemlist {
	max-width: 440px;
	margin-bottom: 5px;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
	text-shadow: 2px 2px var(--colorWhiteShades7);
}

.divider {
	border-top: 5px solid var(--colorShades2);
	border-radius: 10px;
	width: 100%;
	margin-top: 20px;
	margin-bottom: -10px;
}

.itemdivider {
	border-top: 5px solid var(--colorShades2);
	border-radius: 10px;
	width: 100%;
	margin-top: 20px;
	margin-bottom: -10px;
}

.nametitle {
	max-width: 440px;
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	color: #fff;
	margin-bottom: 2px;
	text-shadow: 2px 2px var(--colorWhiteShades7);
}

.footercontainer {
	background: var(--colorShades0);
	padding: 0 0 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(../images/footer.svg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow-x: hidden;

	/* position: sticky; */
	position: relative;
	top: 0;
	z-index: 9;
}

@media screen and (max-width: 768px) {
	.footercontainer {
		padding: 0 0 50px;
	}
}

.footerdivider {
	border-top: 4px solid var(--colorShades2);
	border-radius: 10px;
	width: 100%;
}

.footerheading {
	font-family: "Trebucket MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	margin-bottom: 24px;
	font-size: 24px;
}

.footersubtext {
	margin-bottom: 24px;
	font-size: 20px;
}

.footerlinkscontainer {
	width: 100%;
	max-width: 1000px;
	display: flex;
	align-items: stretch;
	z-index: 3;
}

@media screen and (max-width: 768px) {
	.footerlinkscontainer {
		padding-top: 32px;
		justify-content: center;
	}
}

.footerlinkswrapper {
	display: flex;
}

@media screen and (max-width: 768px) {
	.footerlinkswrapper {
		flex-direction: column;
	}
}

.footerlinksitems {
	display: flex;
	flex-direction: column;
	padding: 0 2rem;
	align-items: flex-start;
	margin: 16px;
	text-align: left;
	width: auto;
	box-sizing: border-box;
	color: #fff;
}

@media screen and (max-width: 950px) {
	.footerlinksitems {
		padding: 0 1rem;
	}
}

@media screen and (max-width: 830px) {
	.footerlinksitems {
		margin: 0;
		padding: 10px;
		width: 100%;
	}
}

@media screen and (max-width: 480) {
	.footerlinksitems {
		margin: 0;
		padding: 10px;
		width: 100%;
	}
}

.sociallogo {
	color: #000;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.footerlinktitle {
	margin-bottom: 16px;
	color: var(--colorShades4);
}

.footerlinktext {
	font-size: small;
}

.footerlinksmalltext {
	font-size: x-small;
	align-items: center;
	margin-left: 10px;
}

.websiterights {
	color: #fff;
}

.servicecontainer {
	width: 100%;
	height: 100%;
	padding: 5vh 0;
	z-index: 4;
	/* position: sticky; */
	position: relative;
	top: 0;
	overflow-x: hidden;

	background: radial-gradient(
		ellipse farthest-corner at top left,
		var(--colorShades7) 0%,
		var(--colorShades6) 8%,
		var(--colorShades3) 25%,
		var(--colorShades1) 62.5%,
		var(--colorNavBar) 100%
	);
}

@media screen and (max-width: 768px) {
	.servicecontainer {
		background: radial-gradient(
			ellipse farthest-corner at top right,
			var(--colorShades7) 0%,
			var(--colorShades6) 8%,
			var(--colorShades3) 25%,
			var(--colorShades1) 62.5%,
			var(--colorNavBar) 100%
		);
		padding: 10vh 0;
		position: relative;
	}
}

@media screen and (max-width: 480px) {
	.servicecontainer {
		background: radial-gradient(
			ellipse farthest-corner at top right,
			var(--colorShades7) 0%,
			var(--colorShades6) 8%,
			var(--colorShades3) 25%,
			var(--colorShades1) 62.5%,
			var(--colorNavBar) 100%
		);
		padding: 10vh 0;
		position: relative;
	}
}

.footerdivider {
	border-top: 5px solid var(--colorShades2);
	border-radius: 10px;
	width: 100%;
	margin-top: 0px;
	margin-bottom: 10px;
}

.infowrapper {
	display: grid;
	z-index: 1;
	height: 900px;
	width: 100%;
	max-width: 1100px;
	margin-right: auto;
	margin-left: auto;
	padding: 0 24px;
	justify-content: center;
}

@media screen and (max-width: 768px) {
	.infowrapper {
		height: auto;
	}
}

.inforow {
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);
	align-items: center;
	grid-template-areas: "col2 col1";
}

@media screen and (max-width: 768px) {
	.inforow {
		grid-template-areas: "col1 col1" "col2 col2";
	}
}

.inforowprod {
	display: grid;
	grid-auto-columns: minmax(auto, 1fr);
	align-items: center;
	grid-template-areas: "col1 col2";
}

@media screen and (max-width: 768px) {
	.inforowprod {
		grid-template-areas: "col1 col1" "col2 col2";
	}
}

.column1 {
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col1;
}

.column2 {
	margin-bottom: 15px;
	padding: 0 15px;
	grid-area: col2;
}

.textwrapper {
	max-width: 548px;
	padding-top: 0;
	padding-bottom: 60px;
}

.topline {
	color: #00f691;
	font-size: 16px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 1.4px;
	text-transform: uppercase;
	margin-bottom: 16px;
}

.heading {
	margin-top: 24px;
	margin-bottom: 24px;
	font-size: 32px;
	line-height: 1.1;
	font-weight: 600;
	color: #f7f8fa;
	text-shadow: 2px 2px var(--colorShades0);
}

@media screen and (max-width: 768px) {
	.heading {
		font-size: 32px;
	}
}

@media screen and (max-width: 480px) {
	.heading {
		font-size: 26px;
	}
}

.divider {
	border-top: 5px solid var(--colorShades2);
	border-radius: 10px;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 10px;
}

.itemlist {
	max-width: 440px;
	margin-bottom: 5px;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
	text-shadow: 2px 2px var(--colorShades0);
}

.srvimgwrap {
	max-width: 555px;
	height: 100%;
}

.productscontainer {
	width: 100%;
	height: 100%;
	padding: 10vh 0;
	z-index: 5;
	/* position: sticky; */
	position: relative;
	top: 0;
	overflow-x: hidden;

	background: radial-gradient(
		ellipse farthest-corner at top right,
		var(--colorShades7) 0%,
		var(--colorShades6) 8%,
		var(--colorShades3) 25%,
		var(--colorShades1) 62.5%,
		var(--colorNavBar) 100%
	);
}

@media screen and (max-width: 768px) {
	.productscontainer {
		background: radial-gradient(
			ellipse farthest-corner at top left,
			var(--colorShades7) 0%,
			var(--colorShades6) 8%,
			var(--colorShades3) 25%,
			var(--colorShades1) 62.5%,
			var(--colorNavBar) 100%
		);
		padding: 10vh 0;
		position: relative;
	}
}

@media screen and (max-width: 480px) {
	.productscontainer {
		background: radial-gradient(
			ellipse farthest-corner at top right,
			var(--colorShades7) 0%,
			var(--colorShades6) 8%,
			var(--colorShades3) 25%,
			var(--colorShades1) 62.5%,
			var(--colorNavBar) 100%
		);
		padding: 10vh 0;
		position: relative;
	}
}

.productimgwrap {
	max-width: 100px;
	height: 100%;
}

.productimg {
	display: block;
	max-width: 100%;
	max-height: 100%;
}

.subtitleprod {
	max-width: 440px;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 24px;
	line-height: 24px;
	color: #85e512;
	text-shadow: 2px 2px var(--colorShades0);
}

.textline {
	max-width: 400px;
	margin-bottom: 5px;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
}

.weblink {
	color: var(--colorShades5);
	font-size: 15px;
}

.textwrapperprod {
	max-width: 400px;
	padding-top: 0;
	padding-bottom: 20px;
	color: #fff;
	text-shadow: 2px 2px var(--colorShades0);
}

.headingprod {
	margin-top: 24px;
	margin-bottom: 24px;
	font-size: 32px;
	line-height: 1.1;
	font-weight: 600;
	color: rgb(170, 165, 196);
	text-shadow: 2px 2px var(--colorShades0);
}

@media screen and (max-width: 768px) {
	.headingprod {
		font-size: 32px;
	}
}

@media screen and (max-width: 480px) {
	.headingprod {
		font-size: 26px;
	}
}
